@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-Black.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Black.woff') format('woff'),
    url('../fonts/subset-Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-BlackItalic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-BlackItalic.woff') format('woff'),
    url('../fonts/subset-Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Bold.woff') format('woff'),
    url('../fonts/subset-Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-BoldItalic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-BoldItalic.woff') format('woff'),
    url('../fonts/subset-Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-ExtraBold.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-ExtraBold.woff') format('woff'),
    url('../fonts/subset-Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-ExtraBoldItalic.woff') format('woff'),
    url('../fonts/subset-Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-ExtraLight.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-ExtraLight.woff') format('woff'),
    url('../fonts/subset-Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-SemiBoldItalic.woff') format('woff'),
    url('../fonts/subset-Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-MediumItalic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-MediumItalic.woff') format('woff'),
    url('../fonts/subset-Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Medium.woff') format('woff'),
    url('../fonts/subset-Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-Light.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Light.woff') format('woff'),
    url('../fonts/subset-Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-ExtraLightItalic.woff') format('woff'),
    url('../fonts/subset-Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-SemiBold.woff') format('woff'),
    url('../fonts/subset-Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Regular.woff') format('woff'),
    url('../fonts/subset-Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-Thin.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Thin.woff') format('woff'),
    url('../fonts/subset-Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-Italic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Italic.woff') format('woff'),
    url('../fonts/subset-Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-LightItalic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-LightItalic.woff') format('woff'),
    url('../fonts/subset-Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/subset-Montserrat-ThinItalic.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-ThinItalic.woff') format('woff'),
    url('../fonts/subset-Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
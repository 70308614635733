.card {
    position: relative;
    width: 100% !important;
    max-width: 627px;
    border-radius: 3px;
    &[data-reach-dialog-content] {
        padding: 50px;

        @media (max-width: 768px) {
            max-width: 290px;
            padding: 20px;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    &__block {
        width: 47%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &__img {
        width: 47%;
        height: 158px;
        @media (max-width: 768px) {
            width: 100%;
        }
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            object-fit: cover;

            @media (max-width: 768px) {
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #231F20;
        margin-bottom: 8px;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 160%;
        color: #231F20;

        @media (max-width: 768px) {
            display: none;
        }

        &_mobile {
            cursor: pointer;
            position: relative;
            display: none;
            margin-top: 15px;
            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 15px; 
                transition: all .2s linear;
            }
            img {
                position: absolute;
                top: 0;
                right: 0;
                transition: all .2s linear;
            }
            @media (max-width: 768px) {
                display: flex;
                align-items: center;
            }

            &.open {
                span {
                    white-space: initial;
                    overflow: visible;
                    text-overflow: initial;
                }
                img {
                    transform: rotate(180deg);
                }
            }
        }

        &_red {
            color: #98092D;
        }
    }

    &__status {
        margin-top: 10px;
        .card__text {
            padding-top: 10px;
        }
    }

    &__form {
        margin-top: 20px;
        @media (max-width: 768px) {
            margin-top: 35px;
        }
        &_row {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
            }
            
            .btn_red {
                width: 48%;
                padding: 16px 0;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }

            .form__checkbox {
                @media (max-width: 768px) {
                    margin: 0;
                    margin-bottom: 20px;
                }
            }

            &_mr {
                margin: 0 0 20px;
            }
        }

        &_row-reverse {
            @media (max-width: 768px) {
                flex-direction: column-reverse;
            }
        }

        &_row-margin {
            margin-top: 30px;
            margin-bottom: 25px;

            @media (max-width: 768px) {
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
        &_label {
            width: 48%;
            margin-bottom: 0 !important;
            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 18px;

                @media (max-width: 768px) {
                    position: absolute;
                    top: -85px;
                    left: 0;
                }
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }
        &_input {
            position: relative;

            input {
                font-size: 12px;
            }
        }
        &_suffix {
            position: absolute;
            top: 20px;
            right: 16px;
            font-size: 12px;
        }
        &_slider {
            width: 47%;
            .card__form_row {
                padding-bottom: 8px;

                @media (max-width: 768px) {
                    flex-direction: row;
                }
            }

            p {
                font-size: 16px;
                line-height: 20px;
                color: #C16B81;
            }

            .rc-slider {
                margin-top: 10px;
            }

            .rc-slider-rail {
                background-color: #F5E6EA;
            }

            .rc-slider-track {
                background-color: #98092D;
            }

            .rc-slider-handle {
                width: 24px;
                height: 24px;
                background-color: #98092D;
                border-color: #98092D;
                margin-top: -10px;
            }

            @media (max-width: 768px) {
                width: 100%;
                padding: 0 3px;
                padding-bottom: 18px;
            }
        }

        &_item {
            width: 48%;
            display: flex;
            align-items: center;
            span {
                font-size: 11px;
                line-height: 160%;
                color: #918F8F;
                margin-left: 12px;

                @media (max-width: 768px) {
                    margin-left: 10px;
                   
                }
            }

            @media (max-width: 768px) {
                width: 100%;

                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }

        &_warn {
            display: none;
            font-size: 12px;
            line-height: 160%;
            color: #98092D;
            background: #F5E6EA;
            border-radius: 3px;
            padding: 10px;
            margin-bottom: 23px;
            @media (max-width: 768px) {
                display: block;
            }
        }
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    @media (max-width: 768px) {
        margin-top: 10px;
    }
    
    &.green {
      margin: 20px 0;
      li {
        &:active,
        &.active {
          background-color: rgba(108, 184, 37, .1);
          color: #6CB825;
        }
        &:hover {
          background-color: rgba(108, 184, 37, .1);
        }
      }

      .next,
      .previous {
        svg {
          stroke: #6CB825;
        }
      }
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      transition: all .25s linear;
      font-size: 20px;
      line-height: 28px;
      &.active {
        background-color: rgba(152, 9, 45, 0.1);
        color: #98092D;
      }

      &:hover {
        background-color: rgba(152, 9, 45, 0.05);
      }

      @media (max-width: 768px) {
          font-size: 14px;
          line-height: 1;
          width: 30px;
          height: 30px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
    }

    .previous {
        margin-right: 20px;
    }

    .next {
        margin-left: 20px;
    }

    .next,
    .previous {
        background-color: #FFFFFF;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        svg {
            @media (max-width: 768px) {
                width: 20px;
                height: 20px;
            }
        }
      }
    }
}
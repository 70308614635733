.outlets {
    width: 100%;
    max-width: 1060px;
    min-height: 620px;
    position: relative;
    &[data-reach-dialog-content] {
        width: 100%;
        padding: 50px;

        @media (max-width: 768px) {
            padding: 30px 20px;
        }
    }
    @media (max-width: 768px) {
        max-width: 300px;
        padding: 20px;
        min-height: min-content;
    }
    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #231F20;
        text-transform: uppercase;
        margin-bottom: 30px;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    &__head-mobile {
        display: none;
        font-size: 12px;
        line-height: 15px;
        color: #918F8F;
        margin-bottom: 10px;
        @media (max-width: 768px) {
            display: block;
        }
    }

    p {
        font-size: 16px;
        line-height: 20px;
        color: #231F20;
    }

    .form__label {
        width: 100%;
        input {
            border-color: #E0B5C0;
        }
    }

    &__search-panel {
        position: relative;
    }

    &__dropdown {
        position: absolute;
        top: 55px;
        left: 0;
        right: 0;
        z-index: 100;
        width: 100%;
        min-height: 260px;
        max-height: 260px;
        overflow: auto;
        background: #FCF8F9;
        box-shadow: 0px 15px 20px -22px rgba(152, 9, 45, 0.3);
        padding: 5px 5px 5px 20px;
        
        @media (max-width: 768px) {
            top: 45px;
            padding: 20px 0 20px 10px;
        }

        
        &::-webkit-scrollbar {
            width: 5px;
        }
          
        &::-webkit-scrollbar-track {
            background: #E0B5C0;
        }
          
        &::-webkit-scrollbar-thumb {
            background-color: #98092D;
            border-radius: 20px;
            border: 1px solid #F5E6EA;
        }
    }

    &__list {
        padding: 0;
        &::-webkit-scrollbar {
            width: 0px;
        }
        div {
            display: flex;
            align-items: center;
            padding: 15px 15px 15px 0;

            @media (max-width: 768px) {
                flex-wrap: wrap;
                padding: 0;

                margin-bottom: 30px;
                padding-right: 10px;
            }
        }

        p {
            width: 100%;
            &:nth-child(1) {
                max-width: 170px;
                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 17px;
                    max-width: 82px;
                }
            }

            &:nth-child(2) {
                max-width: 250px;
                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 500;
                    max-width: 145px;
                }
            }

            &:nth-child(3) {
                max-width: 350px;
                margin-right: 30px;
                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 17px;
                    max-width: 145px;
                    margin: 10px 0 10px 82px;
                }
            }
        }

        .btn {
            @media (max-width: 768px) {
                width: 100%;
                padding: 12px 0;
            }
        }
    }

    &__head {
        display: flex;
        padding: 20px;
        border-bottom: 1px solid #FAF3F5;

        @media (max-width: 768px)  {
            display: none;
        }
    }

    &__head-item {
        width: 100%;
        &:nth-child(1) {
            max-width: 200px;
        }

        &:nth-child(2) {
            max-width: 380px;
        }
    }

    
    &__row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__note {
        width: 100%;
        max-width: 650px;
        display: flex;
        align-items: center;
        background: #F5E6EA;
        border-radius: 3px;
        padding: 15px;

        @media (max-width: 768px) {
            position: relative;
            flex-direction: column;
            max-width: 100%;
            padding: 10px;
            padding-left: 46px;
            margin-bottom: 10px;
        }

        img {
            margin-top: -35px;
            width: 15px;
            height: 15px;
            margin-right: 15px;

            @media (max-width: 768px) {
                position: absolute;
                top: 16px;
                left: 16px;
                margin-top: 0;
            }
        }

        p {
            width: 100%;
            max-width: 300px;
            font-weight: 600;
            font-size: 12px;
            line-height: 160%;
            color: #EB5757;
            border-right: 1px solid rgba(145, 143, 143, .2);
            margin-right: 20px;

            @media (max-width: 768px) {
                border: 0;
                margin-right: 0;
                margin-bottom: 10px;
            }
        }

        span {
            display: block;
            font-size: 12px;
            line-height: 160%;
            color: #231F20;
            width: 100%;
            max-width: 270px;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;

        @media (max-width: 768px) {
            padding: 7px;
        }

        img {
            @media (max-width: 768px) {
                width: 18px;
            }
        }
    }

    &__save {
        position: relative;
        width: 100%;
        max-width: 250px;
        &:disabled {
            span {
                opacity: 0;
            }
        }

        @media (max-width: 768px) {
            max-width: 100%;
            padding: 12px 0;
        }
    }
}

.saved {
    padding: 10px 0 30px;
    min-height: 250px;

    @media (max-width: 768px) {
        min-height: min-content;
    }
}

.selected__list {
    
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        @media (max-width: 768px) {
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 0;

            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
        p {
            width: 100%;
            &:nth-child(1) {
                max-width: 200px;
                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 17px;
                    max-width: 82px;
                }
            }

            &:nth-child(2) {
                max-width: 250px;
                @media (max-width: 768px) {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    max-width: 145px;
                }
            }

            &:nth-child(3) {
                max-width: 350px;
                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 17px;
                    max-width: 145px;
                    margin: 10px 0 10px 82px;
                }
            }
        }
    }

    &-btn {
        color: #98092D;
        background-color: #fff;
        border-radius: 3px;
        border: 2px solid #98092D;
        @media (max-width: 768px) {
            width: 100%;
            padding: 12px 0;
        }
        &:hover {
            background-color: #98092D;
            color: #fff;
        }

        &:active,
        &:disabled {
            background-color: #8E092D;
            color: #fff;
        }

        &:disabled {
            opacity: 0.4;
            cursor: no-drop;
        }
    }
}
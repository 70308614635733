.available {
    position: relative;
    &[data-reach-dialog-content] {
        width: 100%;
        max-width: 1300px;
        margin-top: 0;
        min-height: 500px;
        padding-top: 50px;
        @media (max-width: 1180px) {
            max-width: 800px;
        }

        @media (max-width: 768px) {
            max-width: 100%;
            padding: 50px 15px 25px;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
    }

    &__top {
        position: relative;
        z-index: 1000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
        background: #fff;

        @media (max-width: 1180px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__title {
        font-size: 27px;
        font-weight: 400;
        line-height: 1.25;

        @media (max-width: 1180px) {
            margin-bottom: 20px;
        }
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    &__balance {
        font-weight: 300;
        font-size: 20px;
        color: #918f90;
        @media (max-width: 768px) {
            font-size: 16px;
        }
        span {
            font-size: 27px;
            color: #98092D;
            font-weight: 400;
            margin-left: 10px;
            @media (max-width: 768px) {
                font-size: 20px;
            }
        }
    }

    &__loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.brands {
  &.grey {
    background-color: #f9f9f9;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 50px 0 70px;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: -28px;
    }
    @media (max-width: 768px) {
      margin: -28px -12px 0;
      padding: 50px 0;
    }
  }

  &__item {
    @media (max-width: 1024px) {
      width: 30%;
      margin-top: 28px;
    }

    @media (max-width: 768px) {
      width: 26%;
      margin: 28px 12px 0;
    }

    svg {
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
}

// VARS

:root {
  --color_white: 255, 255, 255;
  --color_black: 35, 31, 32;
  --color_red: 152, 9, 45;
  --color_green: 108, 184, 37;
  --color_gray: 196, 196, 196;

  --color_red_hover: 173, 58, 87;
  --color_green_hover: 152, 205, 102;

  --color_red_active: 142, 9, 45;
  --color_green_active: 98, 184, 37;
}
